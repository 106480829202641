import styles from './Button.module.scss'

const Button = (props: {
  children: React.ReactNode, onClick: any, type: 'submit' | 'reset' | 'button' | undefined
}) => {
  return (
    <button className={styles.button} onClick={props.onClick} type={props.type}>
      {props.children}
    </button>
  )
}

export default Button